@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  letter-spacing: 0.8px;
}

:root {
  --color-pri-dark: #020617;
  --color-sec-dark: #0f172a;
  --color-ter-dark: #1e293b;
  --pri: #6a5acde6;
}

body {
  background-color: #020617;
  background-image: radial-gradient(
    circle farthest-side at center center,
    #6a5acde6 0%,
    #020617 100%
  );

  background-attachment: fixed;
}

body::after {
  content: "";
  position: fixed;
  z-index: -1;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  background-color: #020617da;
  /* backdrop-filter: blur(100px); */
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-sec-dark);
}
::-webkit-scrollbar-thumb {
  background-color: var(--pri);
}

/* Styles for the checkbox to act as a toggle switch */
.toggle-checkbox {
  appearance: none;
  width: 50px;
  height: 24px;
  background-color: var(--color-ter-dark);
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  outline: none;
}

/* Style for the circle inside the toggle switch */
.toggle-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: 18px;
  aspect-ratio: 1/1;
  background-color: var(--pri);
  border-radius: 50%;
  transition: transform 0.2s;
}

/* Change styles when the checkbox is checked */
.toggle-checkbox:checked {
  /* background-color: #4caf50; */
}

.toggle-checkbox:checked::before {
  transform: translate(
    26px,
    -50%
  ); /* Move the circle to the right when checked */
}

/* Spotlight with a Modern Gradient */
.spotlight::before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0px;
  background: radial-gradient(
    200px circle at var(--x) var(--y),
    var(--pri),
    /* Deep purple with higher opacity */ var(--pri),
    /* Lighter purple, less opacity */ var(--pri),
    /* Soft pink, very transparent */ transparent
      /* Fully transparent to blend into the background */
  );
  background-attachment: fixed;
}

.spotlight-content {
  z-index: 2;
}

/* shake animation for toggle light and dark mode */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-4px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(4px);
  }
}

/* ClientSpotlights */
.ClientSpotlights {
  background-image: url("Assets/Site-Images/ClientSpotlightsBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left;
}

.lazy-load-image-background {
  height: 100%;
}
